export default {
    list_title_page: 'Lista de Mapare',
    create_title_page: 'Creaza Mapare',
    update_title_page: 'Editeaza Maparea',
    select_type: 'Selecteaza Tipul',
    select_version: 'Selecteaza Versiunea',
    select_products: 'Selecteaza produsele',
    select_brands: 'Selecteaza brandurile',
    display_order: 'Selecteaza Ordinea',
    category: 'Categorie',
    label: 'Eticheta',
    product: 'Produs',

    list_table: {
        header: {
            name: 'Nume',
            partner_name: 'Nume Partner',
            status: 'Status',
            type: 'Tipul',
            created_at: 'Creat La',
            updated_at: 'Modificat La',
            actions: 'Actiuni',
            image_url: 'Imagine',
            number_row: "Nivelul afisarii a produsului"


        },
        type: {
            1 : 'Beneficii',
            2 : 'Oferte',
        }

    },
    buttons: {
        new: "O mapare Noua",
        update: "Editeaza",
        delete: "Sterge",
        cancel: 'Anulare',
        create: 'Creaza',
        save: 'Salveaza',
        continue: 'Continua',
        delete_all: 'Sterge toate datele',
        change_version: 'Modifica versiunea',

        refresh_cache: 'Regenereaza Cache',
        use_version: 'Aplica versiunea',
        add_previous_build: 'Versiuni anterioare',
        create_version: 'Creaza versiune',
        regenerate: 'Regenereaza',
        add_label: 'Adauga o eticheta',
        add_brand: 'Adauga branduri',
        add_product: 'Adauga produse',
        import: 'Importa pentru UpMobil+',
    },
    name_modal: {
        title: 'Creaza',
        message: 'Creaza un nume pentru aceasta mapare',
    },
    actionModal: {
        title: 'Confirmare',
        message: 'Sigur doriți să ștergeți acest articol?',
        delete_all: 'Sigur doriți să ștergeți toate datele',
        regenerate: 'Creaza/Seteaza versiuni',
        select_app_version: 'Selecteaza versiunea aplicatie',
    },
    input: {
        edit_name: 'Editeaza numele maparii',
        edit_type: 'Editeaza tipul maparii',
    },

    import_modal: {
        import: 'Adaugata date de pe fisier',
        select_type: 'Selecteaza tipul',
        password: 'Adauga parola'
    },
    errors: {
        password: 'Parola incorecta, adauga o parola valida',
        file: 'Eroare incarcare file, incearca din nou',
        type: 'Incorect tip, selecteaza tipul corect'
    }
}